import { Link } from "react-scroll";


export default function HeroSection() {
    return <section id="heroSection" className="hero--section--background">
        <div className="hero--section">
        <div className="hero--section--content--box">
            <div className="hero--section--content"> 
                <h1 className="hero--section--title">
                    <span className="hero--section-title--color">
                    Junior Data Scientist <br/>& Big Data Engineer.
                    </span>
                </h1>
                    <p className="hero--section-description">
                    "I transform Data into Insights, Insights into Innovation"
                    </p>
            </div>
            <a href="./pdf/resume.pdf" download="HAMMANI_Mohamed_Resume.pdf">
                    <button className="btn">Download Resume</button>
            </a>
            <button className="btn--contact-me">
                <Link 
                to="Contact"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500} >
                     Contact Me
                </Link>
            </button>
            
        </div>
            <img  className="hero--section--img" src="./img/home.svg" alt="Hero Section" />
        </div>
    </section>
}